export const results = [
  { type: 'explanation' },
  { type: 'divider', label: '2019' },
  {
    type: 'result',
    date: '2019-10-27',
    comp: 'Skabersjöortens Ryttarförening',
    horse: 'NR Jaranero',
    difficulty: ' Medelsvår C:1 68.33%',
    placement: '2',
  },
  {
    type: 'result',
    date: '2019-10-13',
    comp: 'Södra Sallerups Ryttarförning',
    horse: 'NR Jaranero',
    difficulty: ' Medelsvår C:1 67.5%',
    placement: '5',
  },
  {
    type: 'result',
    date: '2019-08-25',
    comp: 'Dagstorpsortens Ryttarförening ',
    horse: 'Decidido LXXXIII',
    difficulty: ' Medelsvår B:5 63.29%',
    placement: '8',
  },
  {
    type: 'result',
    date: '2019-08-18',
    comp: 'DAMOCHA',
    horse: 'NR Jaranero',
    special: 'Champion ridprov, champion gångarter, bästa 5-åriga hingst.',
    difficulty: 'PRE-championat',
    placement: '1',
  },
  {
    type: 'result',
    date: '2019-08-18',
    comp: 'DAMOCHA',
    horse: 'NR Vaquero',
    special: 'Reserv champion ridprov, bästa 4-åriga hingst.',
    difficulty: 'PRE-championat',
    placement: '2',
  },
  {
    type: 'result',
    date: '2019-08-03',
    comp: 'Malmö Ridklubb',
    horse: 'Decidido LXXXIII',
    difficulty: 'Medelsvår B:5 62.55%',
    placement: '5',
  },
  {
    type: 'result',
    date: '2019-06-29',
    comp: 'Lunds Civila Ryttarförening',
    horse: 'NR Jaranero',
    difficulty: 'Lätt A:3 67.71%',
    placement: '3',
  },
  {
    type: 'result',
    date: '2019-06-28',
    comp: 'Lunds Civila Ryttarförening',
    horse: 'NR Jaranero',
    difficulty: 'Lätt A:3 66.14%',
    placement: '3',
  },
  {
    type: 'result',
    date: '2019-06-16',
    comp: 'Trolleholms Ryttarförening',
    horse: 'NR Jaranero',
    difficulty: 'Lätt A:3 62.57%',
    placement: '9',
  },
  {
    type: 'result',
    date: '2019-05-30',
    comp: 'Malmö Ridklubb',
    horse: 'NR Jaranero',
    difficulty: 'Lätt A:3 71.0%',
    placement: '3',
  },
  {
    type: 'result',
    date: '2019-05-19',
    comp: 'Skanör-Falsterbo Ridklubb',
    horse: 'NR Jaranero',
    difficulty: 'Lätt A:3 70.14%',
    placement: '1',
  },
  {
    type: 'result',
    date: '2019-05-12',
    comp: 'Skabersjöortens Ryttarförening',
    horse: 'Decidido LXXXIII',
    difficulty: 'Medelsvår B:5 61.06%',
    placement: '1',
  },
  {
    type: 'result',
    date: '2019-05-11',
    comp: 'Skabersjöortens Ryttarförening',
    horse: 'NR Jaranero',
    difficulty: 'Lätt A:3 66.42%',
    placement: '5',
  },
  {
    type: 'result',
    date: '2019-04-28',
    comp: 'Södra Sallerups Ryttarförning',
    horse: 'NR Jaranero',
    difficulty: 'Lätt B:2 70.0%',
    placement: '4',
  },
  {
    type: 'result',
    date: '2019-04-14',
    comp: 'Sydslättens Ryttarförening',
    horse: 'Decidido LXXXIII',
    difficulty: 'Medelsvår B:5 58.61%',
    placement: '4',
  },
  {
    type: 'result',
    date: '2019-03-31',
    comp: 'Skabersjöortens Ryttarförening',
    horse: 'Decidido LXXXIII',
    difficulty: 'Medelsvår B:2 66.75%',
    placement: '4',
  },
  {
    type: 'result',
    date: '2019-03-30',
    comp: 'Skabersjöortens Ryttarförening',
    horse: 'NR Jaranero',
    difficulty: 'Lätt B:2 71.0%',
    placement: '1',
  },
  {
    type: 'result',
    date: '2019-03-23',
    comp: 'Eslövs Ridklubb',
    horse: 'Decidido LXXXIII',
    difficulty: 'Medelsvår B:2 66.21%',
    placement: '9',
  },
  {
    type: 'result',
    date: '2019-03-02',
    comp: 'Sydslättens Ryttarförening',
    horse: 'Decidido LXXXIII',
    difficulty: 'Medelsvår B:2 65.81%',
    placement: '10',
  },
];

export default results;
